import { TicketshopPage } from "components/TicketshopPage";
import React from "react";

export default function AllEventsPage() {
  return (
    <TicketshopPage
      scriptString={`<script src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js?salesChannel=whitelabel-website" data-fixr-shop-id="b30d347e-edd5-456e-8d73-fdfa51279c0f" data-fixr-theme="light"></script>`}
      imageSrc="/event-logo-all.png"
      imageMaxWidth="562px"
      title="ALL EVENTS"
    />
  );
}
