import { MotionImage } from "components/MotionImage";
import { Page } from "components/Page";
import TicketShop from "components/ticket-shop";
import React from "react";

export function TicketshopPage({
  scriptString,
  imageSrc,
  imageMaxWidth = "none",
  title,
}) {
  return (
    <Page>
      <div className="container mx-auto">
        <div className="flex h-72 md:h-96 md:my-8 items-center">
          <MotionImage
            className="block w-full mx-auto object-contain max-h-full"
            src={imageSrc}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            style={{ maxWidth: imageMaxWidth }}
          />
        </div>
        <h2 className="text-center mb-2">{title}</h2>
        <TicketShop scriptString={scriptString} />
      </div>
    </Page>
  );
}
